<template>
  <div class="max-w-md mx-auto">
    <form class="relative" @submit.prevent="handleEmailSubscribe">
      <input
        type="email"
        v-model="email"
        :placeholder="$t('Enter your email')"
        class="w-full h-14 px-6 py-3 text-base bg-white dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-600 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out dark:text-zinc-100 dark:placeholder-zinc-400"
        required
      />
      <button
        :disabled="loading"
        type="submit"
        class="absolute right-1 top-1/2 transform -translate-y-1/2 h-12 px-6 bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-full transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-700 disabled:opacity-50"
      >
        <span v-if="!loading">{{ $t('Subscribe') }}</span>
        <span v-else class="flex items-center space-x-2">
          <svg
            class="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>{{ $t('Subscribing') }}</span>
        </span>
      </button>
    </form>
    <transition name="fade">
      <div
        v-if="errors"
        class="mt-2 p-2 text-md text-center text-red-500 bg-red-100 dark:bg-red-900 dark:text-red-200 rounded-md"
      >
        {{ errors }}
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showSuccess"
        class="mt-2 p-2 text-md text-center text-green-500 bg-green-100 dark:bg-green-900 dark:text-green-200 rounded-md"
      >
        {{ $t('Thank you for subscribing!') }}
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { handleApiResponse } from '~/server/utils/utils';

const loading = ref(false);
const email = ref('');
const errors = ref(null);
const showSuccess = ref(false);

const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

async function handleEmailSubscribe() {
  errors.value = null;
  showSucess.value = false;

  if (email.value.trim() === '') {
    errors.value = $t('Email is required');
    return;
  }

  if (!isValidEmail(email.value)) {
    errors.value = $t('Please enter a valid email address');
    return;
  }

  loading.value = true;
  errors.value = null;

  const { data, error } = await handleApiResponse('/api/subscribe', {
    method: 'POST',
    body: { email: email.value },
  });

  loading.value = false;

  console.log('Error', error);
  console.log('Data', data);

  if (error.value) {
    errors.value = 'Failed to subscribe. Try again later.';
    return;
  }

  if (data) {
    showSuccess.value = true;
    return;
  }
}
</script>
